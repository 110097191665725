const data = [
  {
    left: 0.1,
    right: 4,
    num: ["top"],
    size: 5.079 * 0.1,
    height: 130,
  },
  {
    left: 0.12,
    right: 4.1,
    num: ["top", "top"],
    size: 4.063 * 0.1,
    height: 130,
  },
  {
    left: 0.15,
    right: 4.2,
    num: ["top", "top"],
    size: 3.044 * 0.1,
    height: 120,
  },
  {
    left: 0.2,
    right: 4.3,
    num: ["top", "top", "top"],
    size: 2.543 * 0.1,
    height: 120,
  },
  {
    left: 0.25,
    right: 4.4,
    num: ["top", "top", "top"],
    size: 2.158 * 0.1,
    height: 120,
  },
  {
    left: 0.3,
    right: 4.5,
    num: ["top", "top", "top", "top"],
    size: 1.651 * 0.1,
    height: 120,
  },
  {
    left: 0.4,
    right: 4.6,
    num: ["top", "top", "top", "top"],
    size: 1.398 * 0.1,
    height: 120,
  },
  {
    left: 0.5,
    right: 4.7,
    num: ["top", "top", "top", "top", "top"],
    size: 0.888 * 0.1,
    height: 120,
  },
  {
    left: 0.6,
    right: 4.8,
    num: ["top", "top", "top", "top", "top", "top"],
    size: 0.877 * 0.1,
    height: 120,
  },
  {
    left: 0.8,
    right: 4.9,
    num: ["top", "top", "top", "top", "top", "top", "top"],
    size: 0.77 * 0.1,
    height: 120,
  },
  {
    left: 1,
    right: 5,
    num: ["top", "top", "top", "top", "top", "top", "top", "top", "top"],
    size: 0.66 * 0.1,
    height: 120,
  },
  {
    left: 1.2,
    right: 5.1,
    num: ["top", "top", "top", "top", "top", "top", "top", "top", "top"],
    size: 0.55 * 0.1,
    height: 120,
  },
  {
    left: 1.5,
    right: 5.2,
    num: ["top", "top", "top", "top", "top", "top", "top", "top", "top"],
    size: 0.48 * 0.1,
    height: 120,
  },
];

export default data;
