import { HashRouter as Router, Route } from "react-router-dom";

import Home from "./home/home";
import Result from "./result/result";
import Main from "./main/main";

const App = () => {
  return (
    <Router>
      <Route exact path="/" component={Home} />
      <Route path="/main" component={Main} />
      <Route path="/result" component={Result} />
    </Router>
  );
};

export default App;
