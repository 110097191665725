export function getStore(data, list) {
  const { lat, lng } = data;

  const result = list
    .filter((item) => item.lat && item.lng && item.address)
    .map((item) => {
      return {
        ...item,
        distance: getFlatternDistance(lat, lng, item.lat, item.lng),
      };
    })
    // .filter((item) => item.distance < 50000)
    .sort((a, b) => {
      return parseFloat(a.distance) > parseFloat(b.distance) ? 1 : -1;
    })
    .slice(0, 5);

  return result;
}

function getFlatternDistance(lat1, lng1, lat2, lng2) {
  const radLat1 = (lat1 * Math.PI) / 180.0;
  const radLat2 = (lat2 * Math.PI) / 180.0;
  const a = radLat1 - radLat2;
  const b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
  const s =
    2 *
    Math.asin(
      Math.sqrt(
        Math.sin(a / 2) ** 2 +
          Math.cos(radLat1) * Math.cos(radLat2) * Math.sin(b / 2) ** 2
      )
    );
  const c = s * 6378.137;
  const d = Math.round(c * 10000) / 10;
  return d;
}

export const distanceToStr = (num) => {
  if (num < 1000) {
    return num + "m";
  }
  return (num / 1000).toFixed(2) + "km";
};
