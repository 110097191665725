import DocumentTitle from "react-document-title";
import React, { useState } from "react";

import styles from "./lightTest.module.css";

const lightToDesc = {
  正常: {
    title:
      "Some of these lines seem darker or sharper to you. You could be astigmatic.",
    tips:
      "To confirm this result, we recommend that you visit an eye care professional for a comprehensive eye health exam.",
  },
  疑似: {
    title: "Congratulations, you don't seem to have an astigmatism problem.",
    tips:
      "However, to verify this result and the health of your eyes, we recommend you visit an eye care professional. ",
  },
};

const LightTest = () => {
  const [value, setValue] = useState("");
  const [showResult, setShowResult] = useState(false);

  const handleChange = (value) => {
    setValue(value);
    setShowResult(false);
  };

  const handleNext = () => {
    setShowResult(true);
  };

  return (
    <DocumentTitle title="Astigmatism">
      <div className="lightTestContainer">
        <img
          src="https://funvisiontool.essilorseechange.com/imgs/light1.jpg"
          className={styles.img}
        />

        <div className={styles.lightTestSelectCon}>
          <div className={styles.lightTestTitle}>
            Do you see a line (1-2-3-4-5-6-7) that is darker or sharper?
          </div>

          <div className={styles.lightRadioBorderLine}></div>

          <div
            className={styles.lightItem}
            onClick={() => handleChange("正常")}
          >
            <div className={styles.lightItemLeft}>
              <div className={styles.lightItemTitle}>Yes</div>
              <div className={styles.lightItemSubTitle}>
                Some of the lines in the picture are darker or sharper than the
                rest
              </div>
            </div>
            {value === "正常" && <RightSvg />}
          </div>
          <div className={styles.lightRadioBorderLine}></div>
          <div
            className={styles.lightItem}
            onClick={() => handleChange("疑似")}
          >
            <div className={styles.lightItemLeft}>
              <div className={styles.lightItemTitle}>No</div>
              <div className={styles.lightItemSubTitle}>
                The lines in the picture all look the same to me
              </div>
            </div>
            {value === "疑似" && <RightSvg />}
          </div>
          <div className={styles.lightRadioBorderLine}></div>
        </div>

        <div className={styles.lightTestBtnCon}>
          <div className={styles.lightTestYes} onClick={handleNext}>
            CONFIRM
          </div>
        </div>

        {showResult && (
          <>
            <div className={styles.lightTestResultTitle}>Astigmatism：</div>
            <div className={styles.lightTestResult}>
              {lightToDesc[value].title}
            </div>
            <div className={styles.lightTestTips}>
              {lightToDesc[value].tips}
            </div>
          </>
        )}
        <div className={styles.safediv}></div>
      </div>
    </DocumentTitle>
  );
};

const RightSvg = () => {
  return (
    <svg
      className={styles.rightSvg}
      t="1618058149839"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="2034"
      width="200"
      height="200"
    >
      <path
        d="M356.633486 866.60326a43.335582 43.335582 0 0 1-30.655697-12.70156L16.595837 544.519749a43.342807 43.342807 0 0 1 0-61.296945 43.328357 43.328357 0 0 1 61.30417 0L356.626261 761.949058l590.788683-590.795908a43.342807 43.342807 0 0 1 61.296945 0 43.335582 43.335582 0 0 1 0 61.30417l-621.437155 621.44438a43.277782 43.277782 0 0 1-30.641248 12.70156z"
        fill="#1296db"
        p-id="2035"
      ></path>
    </svg>
  );
};

export default LightTest;
