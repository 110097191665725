/** 充实下每个度数的介绍 */
const desc1 = (num) => {
  return {
    text: ` Your vision is comparable to the ${
      num === 1 ? "hamster" : "rhino"
    } in the animal kingdom and its vision is ${
      num === 1 ? "0.06" : "0.1"
    } times that of a normal human vision! Get your eyes checked by an eye care professional today!`,
    image: num === 1 ? "cangshu.png" : "xiniu.png",
    downloadImg: num === 1 ? "cangshu.png" : "xiniu.png",
    shareStr: ` My vision is comparable to the ${
      num === 1 ? "hamster" : "rhino"
    }`,
  };
};

const desc2 = (num) => {
  return {
    text: ` Your vision is comparable to the ${
      num === 1 ? "deer" : "cow"
    } in the animal kingdom and its vision is ${
      num === 1 ? "0.38" : "0.2"
    } times that of a normal human vision! Get your eyes checked by an eye care professional today!`,
    image: num === 1 ? "lu.png" : "nainiu.png",
    downloadImg: num === 1 ? "lu.png" : "nainiu.png",
    shareStr: ` My vision is comparable to the ${num === 1 ? "deer" : "cow"}`,
  };
};

const desc3 = (num) => {
  return {
    text: ` Your vision is comparable to the ${
      num === 1 ? "ladybug" : "raccoon"
    } in the animal kingdom and its vision is ${
      num === 1 ? "0.55" : "0.5"
    } times that of a normal human vision! Get your eyes checked by an eye care professional today!`,
    image: num === 1 ? "piaochong.png" : "huanxiong.png",
    downloadImg: num === 1 ? "piaochong.png" : "huanxiong.png",
    shareStr: ` My vision is comparable to the ${
      num === 1 ? "ladybug" : "raccoon"
    }`,
  };
};

const desc4 = (num) => {
  return {
    text: ` Your vision is comparable to the ${
      num === 1 ? "rabbit" : "cat"
    } in the animal kingdom and its vision is ${
      num === 1 ? "0.85" : "0.75"
    } times that of a normal human vision! Get your eyes checked by an eye care professional today!`,
    image: num === 1 ? "tuzi.png" : "mao.png",
    downloadImg: num === 1 ? "tuzi.png" : "mao.png",
    shareStr: ` My vision is comparable to the ${num === 1 ? "rabbit" : "cat"}`,
  };
};

const desc5 = (num) => {
  return {
    text: ` Your vision is comparable to the ${
      num === 1 ? "bear" : "giraffe"
    } in the animal kingdom and its vision is ${
      num === 1 ? "1" : "0.9"
    } times that of a normal human vision! Get your eyes checked by an eye care professional today!`,
    image: num === 1 ? "beijixiong.png" : "changjinglu.png",
    downloadImg: num === 1 ? "beijixiong.png" : "changjinglu.png",
    shareStr: ` My vision is comparable to the ${
      num === 1 ? "bear" : "giraffe"
    }`,
  };
};

const desc6 = (num) => {
  return {
    text: ` Your vision is comparable to the ${
      num === 1 ? "owl" : "lion"
    } in the animal kingdom and its vision is ${
      num === 1 ? "1.5" : "1.65"
    } times that of a normal human vision! Get your eyes checked by an eye care professional today!`,
    image: num === 1 ? "maotouying.png" : "shizi.png",
    downloadImg: num === 1 ? "maotouying.png" : "shizi.png",
    shareStr: ` My vision is comparable to the ${num === 1 ? "owl" : "lion"}`,
  };
};
export const dushuToDesc = {
  3.9: desc1,
  "4.0": desc1, // 0.1 -- 1 个 对一个
  4.1: desc2, // 0.12 --2 个 对两个
  4.2: desc2, // 0.15
  4.3: desc2, // 0.2
  4.4: desc2, // 0.25
  4.5: desc3, // 0.3 -- 3 个 对两个
  4.6: desc3, // 0.4
  4.7: desc3, // 0.5
  4.8: desc4, // 0.6 --- 4 个 对两个
  4.9: desc5, // 0.8   --- 4 个 全队
  "5.0": desc6, // 1
  5.1: desc6, // 1.2
  5.2: desc6, // 1.5
};
