import React, { useState, useEffect } from "react";
import DocumentTitle from "react-document-title";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { dushuToDesc } from "./allSettings";
import { getStore, distanceToStr } from "./utils";
import styles from "./result.module.css";

const Result = (props) => {
  const [showStore, setShowStore] = useState(false);
  const [showStoreList, setShowStoreList] = useState(false);
  const [storeList, setStoreList] = useState([]);

  const [warningStr, setWarningStr] = useState("");
  const [randomNum, setRandomNum] = useState(
    [1, 2][parseInt(Math.random() * 2 + "")]
  );

  const [showModal, setShowModal] = useState(false);

  const goColorTest = () => {
    props.history.push("/main/colorTest");
  };

  const goLightTest = () => {
    props.history.push("/main/lightTest");
  };
  const eyeTest = props.location.search.split("=")[1];

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      setWarningStr("The browser does not support geolocation");
      console.log("浏览器不支持地理定位");
    }
  };

  const showPosition = (position) => {
    setShowStore(true);
    const { latitude: lat, longitude: lng } = position.coords;

    fetch("https://funvisiontool.essilorseechange.com/position.json")
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        console.log(myJson);
        const result = getStore({ lat, lng }, myJson.stores);
        setShowStoreList(true);
        setStoreList(result);
      });
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setWarningStr(
          "Location failed, user refused to request geographic location"
        );
        console.log("定位失败，用户拒绝请求地理位置");
        return;
      case error.PERMISSION_UNAVAILABLE:
        setWarningStr("Location failed. Location information is not available");
        console.log("定位失败，位置信息不可用");
        return;
      case error.TIMEOUT:
        setWarningStr(
          "Location failed, request to get user location timed out"
        );
        console.log("定位失败，请求获取用户位置超时");
        return;
      case error.UNKONEW_ERROR:
        setWarningStr("Positioning failure, positioning system failure");
        console.log("定位失败，定位系统失效");
        return;
    }
  };

  const handleShare = () => {
    navigator
      .share({
        title: "图片分享",
        text: `${
          dushuToDesc[eyeTest](randomNum).shareStr
        }：https://funvisiontool.essilorseechange.com/imgs/result/share/${
          dushuToDesc[eyeTest](randomNum).image
        },  Come and test it：https://funvisiontool.essilorseechange.com/`,
      })
      .then(() => {
        console.log("Share completed successfuly");
      })
      .catch((error) => {
        console.log(`share failed: ${error}`);
      });
  };

  return (
    <DocumentTitle title="Result">
      <div className={styles.resultCon}>
        {showModal && (
          <div className={styles.model}>
            <div
              className={styles.modelMask}
              onClick={() => setShowModal(false)}
            ></div>
            <img
              src={`https://funvisiontool.essilorseechange.com/imgs/result/share2/${
                dushuToDesc[eyeTest](randomNum).image
              }`}
              className={styles.shareImg}
            />
            <div className={styles.saveBtn} onClick={handleShare}>
              Share Image
            </div>
          </div>
        )}
        <div className={styles.title}>Result</div>
        {dushuToDesc[eyeTest] && (
          <>
            <img
              src={`https://funvisiontool.essilorseechange.com/imgs/result/${
                dushuToDesc[eyeTest](randomNum).image
              }`}
              className={styles.img}
            />
            <div className={styles.eyeDesc}>
              {dushuToDesc[eyeTest](randomNum).text}
            </div>
          </>
        )}

        <div className={styles.shareBtn} onClick={() => setShowModal(true)}>
          SHARE IMAGE
        </div>

        <div className={styles.shareBtn1} onClick={goColorTest}>
          To check Color Vision, click here
        </div>
        <div className={styles.shareBtn1} onClick={goLightTest}>
          To check Astigmatism, click here
        </div>

        {showStore && (
          <>
            <div className={styles.dividerCon}></div>
            <div className={styles.itemCon}>
              <div>Nearby stores</div>
              <svg
                t="1620106310735"
                className={styles.positionIcon}
                viewBox="0 0 1024 1024"
                version="1.1"
                p-id="2097"
                width="200"
                height="200"
              >
                <path
                  d="M854.6 289.1c-18.8-43.4-45.7-82.3-79.9-115.7-34.2-33.4-73.9-59.5-118.2-77.8C610.7 76.6 562.1 67 512 67c-50.1 0-98.7 9.6-144.5 28.5-44.3 18.3-84 44.5-118.2 77.8-34.2 33.4-61.1 72.4-79.9 115.7-19.5 45-29.4 92.8-29.4 142 0 70.6 16.9 140.9 50.1 208.7 26.7 54.5 64 107.6 111 158.1 80.3 86.2 164.5 138.9 188.4 153 6.9 4.1 14.7 6.1 22.4 6.1 7.8 0 15.5-2 22.4-6.1 23.9-14.1 108.1-66.8 188.4-153 47-50.4 84.3-103.6 111-158.1C867.1 572 884 501.8 884 431.1c0-49.2-9.9-97-29.4-142zM512 880.2c-65.9-41.9-300-207.8-300-449.1 0-77.9 31.1-151.1 87.6-206.3C356.3 169.5 431.7 139 512 139s155.7 30.5 212.4 85.9C780.9 280 812 353.2 812 431.1c0 241.3-234.1 407.2-300 449.1z m0-617.2c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176z m79.2 255.2C570 539.3 541.9 551 512 551c-29.9 0-58-11.7-79.2-32.8C411.7 497 400 468.9 400 439c0-29.9 11.7-58 32.8-79.2C454 338.6 482.1 327 512 327c29.9 0 58 11.6 79.2 32.8C612.4 381 624 409.1 624 439c0 29.9-11.6 58-32.8 79.2z"
                  p-id="2098"
                  fill="#333333"
                ></path>
              </svg>
            </div>
            <div>{warningStr}</div>
            {showStoreList && storeList.length === 0 && (
              <div>Nearby stores are recruiting</div>
            )}
            {showStoreList &&
              storeList.length > 0 &&
              storeList.map((store) => (
                <div className={styles.cardCon}>
                  {store.Company && (
                    <div className={styles.cardTitle}>{store.Company}</div>
                  )}
                  <div className={styles.cardTitle}>{store.name}</div>
                  <div className={styles.address}>
                    <div className={styles.left}>
                      {`${store.address}   `}
                      <CopyToClipboard text={store.address}>
                        <div className={styles.copyText}>Copy to clipboard</div>
                      </CopyToClipboard>
                    </div>
                    <div className={styles.right}>
                      {distanceToStr(store.distance)}
                    </div>
                  </div>
                  {store.phone && (
                    <>
                      <div className={styles.atdivider}></div>
                      <a className={styles.copyBtn} href={`tel:${store.phone}`}>
                        phone：<div className="phone">{store.phone}</div>
                      </a>
                    </>
                  )}
                </div>
              ))}
          </>
        )}
        <div className={styles.safediv}></div>
      </div>
    </DocumentTitle>
  );
};

export default Result;
