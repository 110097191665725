import DocumentTitle from "react-document-title";
import style from "./home.module.css";

const Home = (props) => {
  const gogo = () => {
    props.history.push("/main/eyeTest");
  };
  return (
    <DocumentTitle title="2.5 NVG">
      <div>
        <img
          src="https://funvisiontool.essilorseechange.com/imgs/home/home1.png"
          style={{ width: "100%" }}
          className={style.img1}
        />
        <img
          src="https://funvisiontool.essilorseechange.com/imgs/home/home2.png"
          style={{ width: "100%" }}
          className={style.img2}
        />
        <div className={style.desc}>
          2.5 New Vision Generation (2.5 NVG), an Essilor Group initiative, aims
          to find new and sustainable ways to provide vision care to underserved
          populations without access to conventional distribution channels.
          Through this simple and fun vision screening tool, we hope to help you
          be more aware of your vision status and seek vision correction and
          protection, if needed.
        </div>
        <div className={style.desc}>
          This test is not a substitute for an eye examination, and the results
          have no diagnostic significance and are for reference only. If your
          result shows you have poor eyesight, please consult a professional
          optometrist as soon as possible. During the testing process, no
          individual data will be collected or stored. Test results will also
          not be collected or stored
        </div>
        <div className={style.btn} onClick={gogo}>
          START TEST
        </div>
        <div className={style.safeView}></div>
      </div>
    </DocumentTitle>
  );
};

export default Home;
