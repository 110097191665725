import React, { useState, useEffect } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import styles from "./main.module.css";

import EyeChart from "../eyeChart/eyeChart";
import EyeTest from "../eyeTest/eyeTest";
import ColorTest from "../colorTest/colorTest";
import LightTest from "../lightTest/lightTest";

const Main = (props) => {
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    switch (props.location.pathname) {
      // case "/main/eyeChart":
      //   setCurrent(0);
      //   return;
      case "/main/eyeTest":
        setCurrent(1);
        return;
      case "/main/colorTest":
        setCurrent(2);
        return;
      case "/main/lightTest":
        setCurrent(3);
        return;
      default:
        setCurrent(1);
        return;
    }
  }, []);

  return (
    <div>
      <div className={styles.content}>
        <Router>
          <Route exact path="/main/eyeChart" component={EyeChart} />
          <Route path="/main/eyeTest" component={EyeTest} />
          <Route path="/main/colorTest" component={ColorTest} />
          <Route path="/main/lightTest" component={LightTest} />
        </Router>
      </div>
      <div className={styles.tabs}>
        {/* <div
          className={`${styles.tabsPanel} ${current === 0 && styles.active}`}
          onClick={() => {
            setCurrent(0);
            props.history.push("/main/eyeChart");
          }}
        >
          acuity chart
        </div> */}
        <div
          className={`${styles.tabsPanel} ${current === 1 && styles.active}`}
          onClick={() => {
            setCurrent(1);
            props.history.push("/main/eyeTest");
          }}
        >
          Fun vision
        </div>
        <div
          className={`${styles.tabsPanel} ${current === 2 && styles.active}`}
          onClick={() => {
            setCurrent(2);
            props.history.push("/main/colorTest");
          }}
        >
          Color vision
        </div>
        <div
          className={`${styles.tabsPanel} ${current === 3 && styles.active}`}
          onClick={() => {
            setCurrent(3);
            props.history.push("/main/lightTest");
          }}
        >
          Astigmatism
        </div>
      </div>
    </div>
  );
};

export default Main;
