import React, { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import Data from "./data";
import styles from "./eyeChart.module.css";

const Home = () => {
  const [result, setResult] = useState(Data);

  useEffect(() => {
    const res = Data.map((item) => {
      item.num = item.num.map((it) => {
        const range = Math.random() * 10;
        let rotate = "";
        if (range < 2.5) {
          rotate = "0deg";
        } else if (range < 5) {
          rotate = "90deg";
        } else if (range < 7.5) {
          rotate = "180deg";
        } else if (range < 10) {
          rotate = "270deg";
        }
        return rotate;
      });
      return item;
    });
    setResult(res);
  }, []);

  return (
    <DocumentTitle title="acuity chart">
      <div>
        <div className={styles.eCon}>
          <div className={styles.eConLeft}>decimal</div>
          <div className={styles.eConCenter}>Standard visual chart</div>
          <div className={styles.eConRight}>full marks</div>
        </div>
        {result.map((item) => (
          <div
            className={styles.eCon}
            style={{ height: item.height }}
            key={item.size}
          >
            <div className={styles.eConLeft}>{item.left}</div>
            <div className={styles.eConCenter}>
              {item.num.map((rotate, idx) => (
                <img
                  src="https://funvisiontool.essilorseechange.com/imgs/e.png"
                  key={idx}
                  style={{
                    width: item.size + "cm",
                    height: item.size + "cm",
                    transform: `rotate(${rotate})`,
                  }}
                />
              ))}
            </div>
            <div className={styles.eConRight}>{item.right}</div>
          </div>
        ))}
        <div className={styles.placeHolder}></div>
      </div>
    </DocumentTitle>
  );
};

export default Home;
