import React, { Component, useEffect, useRef, useState } from "react";

import DocumentTitle from "react-document-title";
import Data from "./data";
import styles from "./eyeTest.module.css";

const nums = [
  [4, 0.1],
  [4.1, 0.12],
  [4.2, 0.15],
  [4.3, 0.2],
  [4.4, 0.25],
  [4.5, 0.3],
  [4.6, 0.4],
  [4.7, 0.5],
  [4.8, 0.6],
  [4.9, 0.8],
  [5, 1],
  [5.1, 1.2],
  [5.2, 1.5],
];

const EyeTest = (props) => {
  // 六个对四个 || 连续对三个 就算正确
  const [current, setCurrent] = useState("4.0");

  const [realIn, setRealIn] = useState(147.32);

  const [list, setList] = useState({});

  const scroll = useRef();

  // 用来显示当前度数的正确错误
  const [result, setResult] = useState([]);
  // 用爱来记录当前度数第几题了
  const [currentNum, setCurrentNum] = useState(0);

  const handleInit = () => {
    setCurrent("4.0");
    setResult([]);
    setCurrentNum(0);
  };

  const handleNext = (direction) => {
    const tmpResult = [...result];
    if (direction === list[current].num[currentNum]) {
      tmpResult.push(true);
    } else {
      tmpResult.push(false);
    }
    setResult(tmpResult);

    if (["4.0"].includes(current)) {
      // 1 个 对一个

      if (tmpResult[0] === true) {
        handleNextDu();
      } else {
        handleResult(false);
      }
    } else if (["4.1", "4.2", "4.3", "4.4"].includes(current)) {
      // 2 个 对两个
      /**
       * 如果有两个答案，对两个，就去下一个度数
       * 如果有一个错误，就去错误结果
       * 否则就下一题
       */

      if (
        tmpResult.filter((item) => item).length === 2 &&
        tmpResult.length === 2
      ) {
        handleNextDu();
      } else if (tmpResult.filter((item) => !item).length > 0) {
        handleResult(false);
      } else {
        handleNextNum();
      }
    } else if (["4.5", "4.6", "4.7"].includes(current)) {
      // 3 个 对两个

      if (
        tmpResult.filter((item) => item).length === 2 &&
        tmpResult.length < 4
      ) {
        handleNextDu();
      } else if (
        tmpResult.filter((item) => !item).length > 1 ||
        tmpResult.length === 3
      ) {
        handleResult(false);
      } else {
        handleNextNum();
      }
    } else if (["4.8"].includes(current)) {
      // 4 个 对两个

      if (
        tmpResult.filter((item) => item).length === 2 &&
        tmpResult.length < 5
      ) {
        handleNextDu();
      } else if (
        tmpResult.filter((item) => !item).length > 2 ||
        tmpResult.length === 4
      ) {
        handleResult(false);
      } else {
        handleNextNum();
      }
    } else if (["4.9", "5.0", "5.1"].includes(current)) {
      // 4 个 对四个
      if (
        tmpResult.filter((item) => item).length === 4 &&
        tmpResult.length < 5
      ) {
        handleNextDu();
      } else if (
        tmpResult.filter((item) => !item).length > 0 ||
        tmpResult.length === 4
      ) {
        handleResult(false);
      } else {
        handleNextNum();
      }
    } else if (["5.2"].includes(current)) {
      // 4 个 对四个
      if (
        tmpResult.filter((item) => item).length === 4 &&
        tmpResult.length < 5
      ) {
        handleResult(true);
      } else if (
        tmpResult.filter((item) => !item).length > 0 ||
        tmpResult.length === 4
      ) {
        handleResult(false);
      } else {
        handleNextNum();
      }
    }
  };

  const handleNextDu = () => {
    setCurrent(parseFloat(Number(current) + 0.1 + "").toFixed(1));
    setCurrentNum(0);
    setResult([]);
  };

  const handleResult = (result) => {
    const result1 = result;
    const current1 = current;
    handleInit();

    // 当前结果错了，就出上一个 current
    // // 当前结果对的，就出当前 current
    if (result1) {
      props.history.push(`/result?eyeTest=${current1}`);
    } else if (current === "3.5") {
      props.history.push(`/result?eyeTest=${3.4}`);
    } else {
      props.history.push(
        `/result?eyeTest=${parseFloat(Number(current1) - 0.1 + "").toFixed(1)}`
      );
    }
  };

  const handleNextNum = () => {
    // 当前度数下一题
    setCurrentNum(currentNum + 1);
  };

  useEffect(() => {
    const result = {};
    Data.map((item) => {
      item.num = [1, 2, 3, 4, 5, 6].map((_) => {
        const range = Math.random() * 10;
        let rotate = "";
        if (range < 2.5) {
          rotate = "0deg";
        } else if (range < 5) {
          rotate = "90deg";
        } else if (range < 7.5) {
          rotate = "180deg";
        } else if (range < 10) {
          rotate = "270deg";
        }
        return rotate;
      });
      result[parseFloat(item.right + "").toFixed(1)] = item;
    });
    setList(result);
  }, []);

  return (
    <DocumentTitle title="Fun vision">
      <div className="eyeTestCon">
        <div className={styles.eyeTesttipsCon}>
          <span className={styles.eyeTesttips}>
            Please keep the mobile phone at 30cm infront of your eye, identify
            the direction of the E
          </span>
        </div>

        {!!list[current] && (
          <div className={styles.eyeTest_eCon}>
            <div className={styles.eyeTest_eConLeft}>{list[current].left}</div>
            <div className={styles.eyeTest_eConCenter}>
              {result.length > 0 && (
                <div className={styles.eyeTest_resultCon}>
                  {result.map((item, idx) => (
                    <img
                      key={idx}
                      src={
                        item
                          ? "https://funvisiontool.essilorseechange.com/imgs/right.png"
                          : "https://funvisiontool.essilorseechange.com/imgs/wrong.png"
                      }
                      className={styles.eyeTest_resultImg}
                    />
                  ))}
                </div>
              )}
              <img
                src="https://funvisiontool.essilorseechange.com/imgs/e.png"
                style={{
                  width: list[current].size + "cm",
                  height: list[current].size + "cm",
                  transform: `rotate(${list[current].num[currentNum]})`,
                }}
              />
            </div>
            <div className={styles.eyeTest_eConRight}>
              {list[current].right}
            </div>
          </div>
        )}

        <div className={styles.ruler}>
          <div className={styles.rulerBancm}></div>
          <div className={styles.rulermm}></div>
        </div>

        <div className={styles.numScroll}>
          {nums.map((num) => (
            <div
              key={num}
              className={`${styles.numItem} ${
                parseFloat(num[0] + "").toFixed(1) === current && styles.active
              }`}
              id={
                "a" +
                parseFloat(num[0] + "")
                  .toFixed(1)
                  .replace(".", "")
              }
            >
              <div className="numText">{num[0]}</div>
              <div className="numText">{num[1]}</div>
            </div>
          ))}
        </div>

        {/* <Scrolldiv
        scrollX
        enableFlex
        className="scrollCon"
        scrollIntodiv={"a" + current.replace(".", "")}
        ref={scroll}
      >
        <div className="numScroll">
          {nums.map((num) => (
            <div
              className={`numItem ${
                parseFloat(num[0] + "").toFixed(1) === current && "active"
              }`}
              id={
                "a" +
                parseFloat(num[0] + "")
                  .toFixed(1)
                  .replace(".", "")
              }
            >
              <div className="numText">{num[0]}</div>
              <div className="numText">{num[1]}</div>
            </div>
          ))}
        </div>
      </Scrolldiv> */}

        <div className={styles.eyeTest_btnCon}>
          <div
            className={styles.eyeTest_btn}
            onClick={() => handleNext("270deg")}
          >
            Up
          </div>
        </div>
        <div className={styles.eyeTest_btnCon}>
          <div
            className={styles.eyeTest_btn}
            onClick={() => handleNext("180deg")}
          >
            Left
          </div>
          <div
            className={`${styles.eyeTest_btn} ${styles.eyeTest_btnUn}`}
            onClick={() => handleNext("111")}
          >
            Cannot see
          </div>
          <div
            className={styles.eyeTest_btn}
            onClick={() => handleNext("0deg")}
          >
            Right
          </div>
        </div>
        <div className={styles.eyeTest_btnCon}>
          <div
            className={styles.eyeTest_btn}
            onClick={() => handleNext("90deg")}
          >
            Down
          </div>
        </div>

        <div className={styles.placeHolder}></div>
      </div>
    </DocumentTitle>
  );
};

export default EyeTest;
